import * as React from "react";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import { Box } from "rebass";
import { IContentProps } from "../../../../components/types/IContentProps";
import IThemeProviderProps from "../../../../components/types/IThemeProviderProps";

interface IArticlesTitle extends IThemeProviderProps {
    value: IContentProps;
}

class ArticlesTitle extends React.Component<IArticlesTitle> {
    public render() {
        return (
            <>
                <Box
                    width={[1, 3 / 5]}
                    style={{
                        margin: "0 auto",
                        textAlign: "center",
                    }}
                >
                    <SectionSubtitle>
                        <ReactMarkdown
                            source={this.props.value.subtitle?.toString()}
                        />
                    </SectionSubtitle>
                    <SectionTitle>
                        <ReactMarkdown
                            source={this.props.value.title.toString()}
                        />
                    </SectionTitle>
                </Box>
            </>
        );
    }
}

export default ArticlesTitle;

const SectionTitle = styled(Headings.h3)`
    margin: 2em;
    font-family: ${(p) => p.theme.fonts.serif};
    font-weight: 100;
    text-align: center;
    color: ${p => p.theme.colors.accent};
`;

const SectionSubtitle = styled(Headings.h4)`
    font-family: ${(p) => p.theme.fonts.sansSerif};
    letter-spacing: 0.5em;
    text-transform: uppercase;
`;
