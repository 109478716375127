import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import { useColorMode } from "theme-ui";

import themeProps from "../../gatsby-plugin-theme-ui/index";
import IThemeProviderProps from "../types/IThemeProviderProps";

import GridContent from "../components/containers/GridContent";
import { IBlockProps } from "../components/types/IBlockProps";

const content: IBlockProps = {
    id: "2e6e6791-d0bb-4543-b731-4fde3d5dea03",
    slug: "block:overview",
    header: {
        id: "eb24ce8d-cb06-4e09-9438-22933a916a8d",
        slug: "header:overview",
        content: [{
            id: "136ea96b-516c-48ce-bf2c-059ce51f9227",
            slug: "header:title:overview",
            title: "Our Design Approach",
        }],
    },
    containers: [
        {
            id: "737af4ac-fe34-433d-9e18-387c51f46676",
            slug: "containers:overview",
            content: [
                {
                    id: "3378b5b6-07b0-4b5a-8bed-8d12577ed164",
                    slug: "content:overview:ideate",
                    title: "Ideate",
                    text:
                        "Let us understand the look and feel that you’re going for",
                },
                {
                    id: "89d27fb7-7d26-4e61-aecf-9b9a2464d6d9",
                    slug: "content:overview:design",
                    title: "Design",
                    text:
                        "Our interior designers will create spaces to inspire you",
                },
                {
                    id: "9263d2a1-7a2b-4045-9194-9997fef05e23",
                    slug: "content:overview:furnish",
                    title: "Furnish",
                    text: "Outfit your space with hand made to order furnishings",
                },
            ],
        },
    ],
};

interface IOverviewContentProps {
    fill?: string;
}

class OverviewContent extends React.Component<IOverviewContentProps> {
    public render() {
        return (
            <>
                <GridContent
                    value={content}
                    fill={this.props.fill}
                />
            </>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <OverviewContent {...props} fill={fill} />;
};

export default StateWrapper(OverviewContent);
