import React from "react";
import { useColorMode } from "theme-ui";
import styled from "@emotion/styled";

import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Paginator from "@components/Navigation/Navigation.Paginator";
import SocialLinks from "@components/SocialLinks";
import Subscription from "@components/Subscription";

import ArticlesHero from "../sections/articles/Articles.Hero";
import ArticlesList from "../sections/articles/Articles.List";
import ArticlesTitle from "../components/Section/Articles.Title";

import { Box } from "@rebass/grid";
import { graphql, StaticQuery } from "gatsby";
import MainCoverImpl from "../sections/cover/MainCoverImpl";
import ProductCoverImpl from "../sections/cover/ProductCoverImpl";
import CallToAction from "../../../components/funnel/CallToAction";
import OverviewContent from "../../../content/OverviewContent";
import AppRegistry from "../../../meta/AppRegistry";

const contentQuery = graphql`
    query {
        allSite {
            edges {
                node {
                    siteMetadata {
                        name
                        social {
                            url
                            name
                        }
                    }
                }
            }
        }
    }
`;

const mainTitle = {
    id: "0165",
    slug: "explore-mission",
    title: "Exclusive & Curated Designer Insights",
    updatedAt: "2021-01-17T23:42:01.32941Z",
};

const coverContent = {
    id: "eee9b1aed927434ea291061e1a7a9d04",
    subtitle: "Collection",
    title: ["Living Room"],
    text:
        "Loungers, Sectionals, and Chaise designed with italian hand-stitched fabrics and reclaimed woods.",
    media: {
        placeholder:
            "https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/image-cdn/tt1-sofa-nuvhas.jpg",
    },
    route: "/designs/eee9b1aed927434ea291061e1a7a9d04",
};


interface IArticlesProps {
    location: any;
    pageContext: any;
    fill: string;
}

class ArticlesPage extends React.Component<IArticlesProps> {
    public render() {
        let { location, pageContext } = this.props;
        const articles = pageContext.group;
        const authors = pageContext.additionalContext.authors;

        return (
            <Layout>
                <MainCoverImpl
                    content={{
                        fill: this.props.fill,
                        subtitle: AppRegistry.Tags.Header,
                        title: AppRegistry.Tags.Compose,
                        text: AppRegistry.Description.Hero,
                    }}
                />

                <ProductCoverImpl
                    content={{
                        title: coverContent.title,
                        subtitle: coverContent.subtitle,
                        text: coverContent.text,
                    }}
                    media={{
                        placeholder: coverContent.media.placeholder
                    }}
                    route={coverContent.route}
                />

                <StaticQuery
                    query={contentQuery}
                    render={(data) => {
                        const {
                            social,
                        } = data.allSite.edges[0].node.siteMetadata;

                        return (
                            <>
                                <SEO pathname={location.pathname} />

                                <Section narrow>
                                    <OverviewContent />
                                    <CallToAction />
                                </Section>

                                <Section narrow>
                                    <ArticlesTitle value={mainTitle} />
                                    <ArticlesHero authors={authors} />
                                    <ArticlesList articles={articles} />
                                    <ArticlesPaginator
                                        show={pageContext.pageCount > 1}
                                    >
                                        <Paginator {...pageContext} />
                                    </ArticlesPaginator>
                                    <Box m={[2, 3, 5]} />
                                </Section>

                                <Section>
                                    <CallToAction />

                                    <Subscription />

                                    <Box
                                        mt={[2, 3, 5]}
                                        width={1}
                                        style={{
                                            textAlign: "center",
                                            transform: "scale(1.5)",
                                        }}
                                    >
                                        <SocialLinks links={social} />
                                    </Box>
                                </Section>
                            </>
                        );
                    }}
                />
            </Layout>
        );
    }
}

const StateWrapper = (component) => (props) => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === "dark";
    const fill = isDark ? "#fff" : "#000";

    return <ArticlesPage {...props} fill={fill} />;
};

export default StateWrapper(ArticlesPage);

const ArticlesPaginator = styled.div<{ show: boolean }>`
    ${(p) => p.show && `margin-top: 95px;`}
`;
