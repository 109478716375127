import * as React from "react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import themeProps from "../../gatsby-plugin-theme-ui/index";
import IThemeProviderProps from "../types/IThemeProviderProps";

import { Image } from "rebass";
import { Flex, Box } from "@rebass/grid";
import { v1 as Guid } from "uuid";
import { Content } from "@digitaltransformation/qone.pages.layout";
import { IBlockProps } from "../types/IBlockProps";

interface IGridContentProps extends IThemeProviderProps {
    value: IBlockProps;
}

/**
 * Expertise flexbox n-panel grid
 */
class GridContent extends React.Component<IGridContentProps, {}> {
    public render() {
        const { id, header, containers } = this.props.value;

        return (
            <Flex flexWrap={"wrap"} key={id}>
                <Box
                    width={1}
                    style={{
                        textAlign: "center",
                        color: this.props.fill,
                    }}
                >
                    <Content
                        title={{
                            children: <>{header.content[0].title}</>,
                            style: {
                                textTransform: "capitalize",
                                fontFamily: `${themeProps.fonts.serif}`,
                                fontSize: "1.5em",
                                fontWeight: "700",
                                color: `${this.props.fill}`,
                                lineHeight: 1,
                            },
                        }}
                        subtitle={{
                            children: <>{header.content[0].subtitle}</>,
                            style: {
                                fontSize: "1.75em",
                                fontWeight: "700",
                                lineHeight: 1,
                            },
                        }}
                        key={Guid()}
                    />
                </Box>
                <Flex width={1} flexWrap={"wrap"}>
                    {containers.map((c) =>
                        c.content.map((cx) => (
                            <Box
                                width={[1, 1 / 3]}
                                p={[2, 3, 4]}
                                key={Guid()}
                                style={{
                                    textAlign: "center",
                                    color: this.props.fill,
                                }}
                            >
                                <StyledContent
                                    // {...c.image && {
                                    //     title: {
                                    //         children: <>
                                    //             {c.image && <Image
                                    //                 src={'https:' + c.image?.fluid.src}
                                    //                 alt={''}
                                    //                 width={[3 / 5]}
                                    //                 maxWidth={'256px'}
                                    //                 m={4}
                                    //             />}
                                    //         </>,
                                    //     }
                                    // }}
                                    {...(cx.title && {
                                        subtitle: {
                                            children: <>{cx.title}</>,
                                            style: {
                                                fontWeight: "700",
                                                fontSize: "1.25em",
                                                lineHeight: "2",
                                            },
                                        },
                                    })}
                                    {...(cx.text && {
                                        text: {
                                            children: (
                                                <>
                                                    <ReactMarkdown
                                                        source={cx.text}
                                                    />
                                                </>
                                            ),
                                            style: {
                                                fontFamily:
                                                    "OpenSans, sans-serif",
                                                fontWeight: "100",
                                                lineHeight: "1.5",
                                            },
                                        },
                                    })}
                                    m={[1, 2, 3]}
                                    p={[2]}
                                />
                            </Box>
                        ))
                    )}
                </Flex>
            </Flex>
        );
    }
}

export default GridContent;

const StyledContent = styled(Content)`
    transition: ${themeProps.transition};
    border-radius: 15px;

    &:hover {
        background: ${themeProps.colors.hover};
        box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
        transform: scale(1.1) translateY(-15px);
    }
`;
